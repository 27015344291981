import React, { Component } from "react";

import "./ToolTip.css";

class ToolTip extends Component {
  state = {
    active: false,
  };

  static defaultProps = {
    direction: "top",
  };

  showTip = () => {
    this.setState({
      active: true,
    });
  };

  hideTip = () => {
    this.setState({
      active: false,
    });
  };

  render() {
    const { active } = this.state;
    return (
      <div
        className={"Wrapper"}
        onMouseEnter={this.showTip}
        onMouseLeave={this.hideTip}
      >
        {this.props.children}
        {active && (
          <div className={`tip ${this.props.direction}`}>
            {this.props.content}
          </div>
        )}
      </div>
    );
  }
}
export default ToolTip;

import {
  CBECS_RECS_EUB_COLORS,
  WATER_EUB_COLORS,
} from "./default-chart-colors";
import { END_USES } from "./end-uses";

export const defaultEndUseLabels = [
  {
    name: "computing-energy-estimate",
    label: "Computing",
    color: CBECS_RECS_EUB_COLORS[END_USES.COMPUTING],
  },
  {
    name: "cooking-energy-estimate",
    label: "Cooking",
    color: CBECS_RECS_EUB_COLORS[END_USES.COOKING],
  },
  {
    name: "cooling-energy-estimate",
    label: "Cooling",
    color: CBECS_RECS_EUB_COLORS[END_USES.COOLING],
  },
  {
    name: "dhw-energy-estimate",
    label: "Water Heating",
    color: CBECS_RECS_EUB_COLORS[END_USES.WATER_HEATING],
  },
  {
    name: "heating-energy-estimate",
    label: "Heating",
    color: CBECS_RECS_EUB_COLORS[END_USES.HEATING],
  },
  {
    name: "lighting-energy-estimate",
    label: "Lighting",
    color: CBECS_RECS_EUB_COLORS[END_USES.LIGHTING],
  },
  {
    name: "office-equipment-energy-estimate",
    label: "Office Equipment",
    color: CBECS_RECS_EUB_COLORS[END_USES.OFFICE_EQUIPMENT],
  },
  {
    name: "other-estimate",
    label: "Other",
    color: CBECS_RECS_EUB_COLORS[END_USES.OTHER],
  },
  {
    name: "refrigeration-energy-estimate",
    label: "Refrigeration",
    color: CBECS_RECS_EUB_COLORS[END_USES.REFRIGERATION],
  },
  {
    name: "ventilation-estimate",
    label: "Ventilation",
    color: CBECS_RECS_EUB_COLORS[END_USES.VENTILATION],
  },
];

export const residentalEndUseLabels = [
  {
    name: "dishwasher-energy-estimate",
    label: "Dishwashers",
    color: CBECS_RECS_EUB_COLORS[END_USES.DISHWASHER],
  },
  {
    name: "heating-energy-estimate",
    label: "Heating",
    color: CBECS_RECS_EUB_COLORS[END_USES.HEATING],
  },
  {
    name: "hw-energy-estimate",
    label: "Water Heating",
    color: CBECS_RECS_EUB_COLORS[END_USES.WATER_HEATING],
  },
  {
    name: "microwave-energy-estimate",
    label: "Microwaves",
    color: CBECS_RECS_EUB_COLORS[END_USES.MICROWAVES],
  },
  {
    name: "cooking-energy-estimate",
    label: "Cooking",
    color: CBECS_RECS_EUB_COLORS[END_USES.COOKING],
  },
  {
    name: "lighting-energy-estimate",
    label: "Lighting",
    color: CBECS_RECS_EUB_COLORS[END_USES.LIGHTING],
  },
  {
    name: "other-energy-estimate",
    label: "Other",
    color: CBECS_RECS_EUB_COLORS[END_USES.OTHER],
  },
  {
    name: "refrigeration-energy-estimate",
    label: "Refrigeration",
    color: CBECS_RECS_EUB_COLORS[END_USES.REFRIGERATION],
  },
  {
    name: "freezer-energy-estimate",
    label: "Freezers",
    color: CBECS_RECS_EUB_COLORS[END_USES.FREEZERS],
  },
  {
    name: "pool-energy-estimate",
    label: "Pools",
    color: CBECS_RECS_EUB_COLORS[END_USES.POOLS],
  },
  {
    name: "fan-energy-estimate",
    label: "Fans",
    color: CBECS_RECS_EUB_COLORS[END_USES.FANS],
  },
  {
    name: "tv-energy-estimate",
    label: "TVs",
    color: CBECS_RECS_EUB_COLORS[END_USES.TVS],
  },
  {
    name: "cooling-energy-estimate",
    label: "Cooling",
    color: CBECS_RECS_EUB_COLORS[END_USES.COOLING],
  },
  {
    name: "hottub-energy-estimate",
    label: "Hot Tub",
    color: CBECS_RECS_EUB_COLORS[END_USES.HOT_TUB],
  },
  {
    name: "dryer-energy-estimate",
    label: "Clothes Dryer",
    color: CBECS_RECS_EUB_COLORS[END_USES.CLOTHES_DRYER],
  },
  {
    name: "washer-energy-estimate",
    label: "Clothes Washer",
    color: CBECS_RECS_EUB_COLORS[END_USES.CLOTHES_WASHER],
  },
];

export const residentalWaterEndUseLabels = [
  {
    name: "water-landscape",
    label: "Landscaping",
    color: WATER_EUB_COLORS[END_USES.CLOTHES_WASHERS],
  },
  {
    name: "water-toilet",
    label: "Toilet",
    color: WATER_EUB_COLORS[END_USES.DISHWASHER],
  },
  {
    name: "water-bath",
    label: "Shower/Bath",
    color: WATER_EUB_COLORS[END_USES.FAUCETS],
  },
  {
    name: "water-faucet",
    label: "Faucets",
    color: WATER_EUB_COLORS[END_USES.LANDSCAPING],
  },
  {
    name: "water-washer",
    label: "Clothes Washers",
    color: WATER_EUB_COLORS[END_USES.LEAKS],
  },
  {
    name: "water-dishwasher",
    label: "Dishwasher",
    color: WATER_EUB_COLORS[END_USES.OTHER],
  },
  {
    name: "water-leaks",
    label: "Leaks",
    color: WATER_EUB_COLORS[END_USES.SHOWER_BATH],
  },
  {
    name: "water-other",
    label: "Other",
    color: WATER_EUB_COLORS[END_USES.TOILET],
  },
];

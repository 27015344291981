import { ApolloClient, ApolloLink, InMemoryCache, HttpLink } from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import OfflineLink from './OfflineLink';

const offlineLink = new OfflineLink({storage: window.sessionStorage});

const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors)
        graphQLErrors.map(({ message, locations, path }) =>
          console.log(
            `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${path}`
          )
        );
      if (networkError) console.log(`[Network error]: ${networkError}`);
    }),
    offlineLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      credentials: "same-origin"
    })
  ]),
  cache: new InMemoryCache()
});

offlineLink.setup(client);

export default client;
export {offlineLink};

import { Modal } from "react-bootstrap";
import React from "react";
import Button from "../Button/Button";
import "./ConfirmationModal.css";

const ConfirmationModal = ({
  show,
  handleClose,
  handleSubmit,
  title,
  body,
  isEditEnabled
}) => {
  return (
    <Modal show={show}>
      <Modal.Header>
        <Modal.Title>{title || "Confirmation"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>{body || "Are you sure to perform the action?"}</Modal.Body>
      <Modal.Footer>
        <Button onClick={handleClose}>
          {isEditEnabled ? "Cancel" : "No"}
        </Button>
        
        <Button primary onClick={handleSubmit}>
          {isEditEnabled ? "Resubmit" : "Yes"}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default ConfirmationModal;

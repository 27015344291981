export const numberWithCommas = (x, type) => {
  if (x === null || x === undefined) {
    return '';
  }
  if (type === 'savings-range') {
    let ranges = x.toString().split(' - ');
    return ranges
      .map((range) => {
        let parts = range.toString().split('.');
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        return parts.join('.');
      })
      .join(' - ');
  }
  if (isNaN(x)) {
    return x;
  }
  var parts = x.toString().split('.');
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return parts.join('.');
};

export const checkIsResidentalEndUse = (buildingUse) =>
  [
    'mobile-home',
    'single-family-detached',
    'single-family-attached',
    'apartment-small',
    'apartment-medium',
  ].includes(buildingUse);

export const checkZeroNaturalGas = (label) =>
  [
    'Computing',
    'Cooling',
    'Clothes Washer',
    'Dishwashers',
    'Fans',
    'Freezers',
    'Lighting',
    'Microwaves',
    'Office Equipment',
    'Refrigeration',
    'TVs',
    'Ventilation',
  ].includes(label);

export const shiftZeroNaturalGasDataToTheEnd = (data, energyType) => {
  if (isNaturalGasEnergyType(energyType)) {
    const zeroNaturalGasData = data.filter((item) =>
      checkZeroNaturalGas(item.label)
    );
    const nonZeroNaturalGasData = data.filter(
      (item) => !checkZeroNaturalGas(item.label)
    );
    return [...nonZeroNaturalGasData, ...zeroNaturalGasData];
  }
  return data;
};

export const isWaterEnergyType = (energyType) =>
  ['waterUse', 'waterCost'].includes(energyType);

export const isElectricityEnergyType = (energyType) =>
  ['electricityUse', 'electricityCost'].includes(energyType);

export const isNaturalGasEnergyType = (energyType) =>
  ['naturalGasUse', 'naturalGasCost'].includes(energyType);

export const hasEndUseDataForFuelTypes = (eubEndUse) => {
  let hasElectricData = false;
  let hasNaturalGasData = false;
  let hasWaterData = false;
  Object.entries(eubEndUse).map(([use, analysis]) => {
    if (analysis.estimated_consumption_elec || analysis.estimated_cost_elec) {
      hasElectricData = true;
    }
    if (analysis.estimated_consumption_gas || analysis.estimated_cost_gas) {
      hasNaturalGasData = true;
    }
    if (
      analysis.water?.estimated_consumption ||
      analysis.water?.estimated_cost
    ) {
      hasWaterData = true;
    }
  });
  return { hasElectricData, hasNaturalGasData, hasWaterData };
};

import React from "react";
import ReactFC from "react-fusioncharts";
import FusionCharts from "fusioncharts";
import Doughnut2D from "fusioncharts/fusioncharts.charts";
import FusionTheme from "fusioncharts/themes/fusioncharts.theme.fusion";

FusionCharts.options.license({
  key:
    "nA-16C5C-11coI3A1A7B1A6C7F6C4G4F4H2A2B2lF-11wE1G4xI-7lrgA3B4vbsH3B5D7C2B1F1D1B4D4E3B1C8E6C2A1E2juwB3B7D1F-11D1D3G4rqb1B9D2C6njyD3H4A9bzfE3D4A2I4E1A9B3D7E2B2G2yqsC2B2ZC7egvH4I3B8oD-13B5QD5D3jteD5B5B2E5B4E4F3H2B8A5E4E4A3D1B-8==",
  creditLabel: false,
});

ReactFC.fcRoot(FusionCharts, Doughnut2D, FusionTheme);

export const FUSION_CHARTS_TYPE = {
  Doughnut2D: "doughnut2d",
  Pie2D: "pie2d",
  MSCombiDY2D: "mscombidy2d",
  MSCombi2D: "mscombi2d",
  MSStackedColumn2DLineDY: "msstackedcolumn2dlinedy",
  MSStackedColumn2D: "msstackedcolumn2d",
  StackedColumn2DLineDY: "stackedcolumn2dlinedy",
  StackedColumn2DLine: "stackedcolumn2dline",
  StackedColumn2D: "stackedcolumn2d",
  StackedArea2DLineDY: "stackedarea2dlinedy",
  StackedArea2D: "stackedarea2d",
  StackedBar2D: "stackedbar2d",
  StackedBar2DWithLine: "msstackedcolumn2dlinedy",
};

const defaultChartConfigs = {
  type: "doughnut2d",
  width: "100%",
  dataFormat: "json",
};

const getChartConfig = ({ chartConfig, height, data }) => {
  return {
    ...defaultChartConfigs,
    height: height,
    dataSource: {
      chart: {
        ...chartConfig,
        paletteColors:
          "5D62B4,28C3BE,F27370,FFC532,61B58E,BC96DF,67CDF2,E58403,60CA9E,8F5097,569C3F,2797F1,BF5119,306004,32372E,5C97E0,2D6CA2,5B6770,8F6A2A,5F407A,872B4B,572C5F,244C5A,2596BE",
      },
      data: data,
    },
  };
};

export default function Donut2DChart({ chartConfig, height, data }) {
  return <ReactFC {...getChartConfig({ chartConfig, height, data })} />;
}

import React from "react";
import TextElement from "../TextElement/TextElement";
import InputQuestion from "../Question/InputQuestion";
import SelectQuestion from "../Question/SelectQuestion";
import TextEditor from "../Question/TextEditor";
import ToolTip from "../ToolTip";
import EndUseBreakdown from "../EndUseBreakdown/EndUseBreakdown";
import { RESPONSE_STATUS_COMPLETED, TABS } from "../../utils/constants";

import "./Page.css";

function evaluateCondition(condition, formValues) {
  if (condition.and) {
    for (let c of condition.and) {
      if (!evaluateCondition(c, formValues)) return false;
    }
    return true;
  }
  if (condition.or) {
    for (let c of condition.or) {
      if (evaluateCondition(c, formValues)) return true;
    }
    return false;
  }

  let fieldValue = formValues[condition.field];
  let gt, gte, lt, lte, eq, includes, contains, neq, notNull;
  gt = gte = lt = lte = eq = includes = contains = neq = notNull = true;
  if (condition.gt || condition.gt === 0 || condition.gt === "")
    gt = fieldValue > condition.gt;
  if (condition.gte || condition.gte === 0 || condition.gte === "")
    gte = fieldValue >= condition.gte;
  if (condition.lt || condition.lt === 0 || condition.lt === "")
    lt = fieldValue < condition.lt;
  if (condition.lte || condition.lte === 0 || condition.lte === "")
    lte = fieldValue <= condition.lte;
  if (condition.eq || condition.eq === 0 || condition.eq === "")
    eq = fieldValue === condition.eq;
  if (condition.neq || condition.neq === 0 || condition.neq === "")
    neq = fieldValue !== condition.neq;
  if (condition.notNull)
    notNull = fieldValue !== undefined && fieldValue !== null;
  if (condition.in) {
    includes = condition.in.indexOf(fieldValue) >= 0;
  }
  if (condition.contains) {
    if (!fieldValue) {
      contains = false;
    } else {
      contains = fieldValue.indexOf(condition.contains) >= 0;
    }
  }

  return gt && gte && lt && lte && eq && includes && contains && neq && notNull;
}

function evaluateConditions(conditions, formValues, condition) {
  if (conditions === null && condition != null) {
    return evaluateCondition(condition, formValues);
  }
  if (conditions.length === 0) return true;

  let conditionAccepted = false;
  for (let condition of conditions) {
    let operation = condition.operation || "OR";
    let thisConditionAccepted = evaluateCondition(condition, formValues);
    if (operation === "OR")
      conditionAccepted = conditionAccepted || thisConditionAccepted;
    if (operation === "AND")
      conditionAccepted = conditionAccepted && thisConditionAccepted;
  }
  return conditionAccepted;
}

function getConditionalState(conditionalStates, answers) {
  let state = "SHOW";
  if (!conditionalStates || !answers) return state;
  let formValues = {};
  for (let answer of answers) formValues[answer.field] = answer.value;

  for (let conditionalState of conditionalStates) {
    let conditionResult = evaluateConditions(
      conditionalState.conditions,
      formValues,
      conditionalState.condition
    );
    if (conditionResult) return conditionalState.state;
  }
  return state;
}

function RenderContentType({
  onAddressChanged,
  session = {},
  validator,
  disableField,
  onUpdating,
  survey,
  ...content
}) {
  switch (content.type) {
    case "text":
      return (
        <div
          className="row"
          style={{ display: content.state === "HIDE" ? "none" : "block" }}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
            {content.title && <h3>
              <div className="d-flex">
                <span>{content.title}</span>
                {content.infoField && (
                  <ToolTip
                    content={<span>{content.infoField}</span>}
                    direction="right"
                  >
                    <i className="bi bi-info-circle icon"></i>
                  </ToolTip>
                )}
              </div>
            </h3>}
            {content.body && <TextElement>{content.body}</TextElement>}
          </div>
        </div>
      );
    case "html":
      return (
        <div
          className="row"
          style={{ display: content.state === "HIDE" ? "none" : "block" }}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
            <h3>
              <div className="d-flex">
                <span>{content.title}</span>
                {content.infoField && (
                  <ToolTip
                    content={<span>{content.infoField}</span>}
                    direction="right"
                  >
                    <i className="bi bi-info-circle icon"></i>
                  </ToolTip>
                )}
              </div>
            </h3>
            <div dangerouslySetInnerHTML={{ __html: content.body }} />
          </div>
        </div>
      );
    case "sub_text":
      return (
        <div
          className="row"
          style={{ display: (content.state === "HIDE" || content.title === "") ? "none" : "block" }}
        >
          <div className="col-lg-12 col-md-12 col-sm-12 pb-3">
            <h5 style={{ marginTop: "2rem", marginBottom: "0rem" }}>
              <div className="d-flex">
                <span>{content.title}</span>
                {content.infoField && (
                  <ToolTip
                    content={<span>{content.infoField}</span>}
                    direction="right"
                  >
                    <i className="bi bi-info-circle icon"></i>
                  </ToolTip>
                )}
              </div>
            </h5>
            <TextElement>{content.body}</TextElement>
          </div>
        </div>
      );
    case "input":
      return (
        <InputQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
        />
      );
    case "text_area":
      return (
        <TextEditor
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
          onUpdating={onUpdating}
        />
      );
    case "select":
      return (
        <SelectQuestion
          content={content}
          session={session}
          validator={validator}
          disableField={disableField}
          answer={(session.answers || []).find(
            (answer) => answer.question === content._id
          )}
        />
      );
    case "chart":
      return <EndUseBreakdown survey={survey} />;
    default:
      return null;
  }
}

function ContentElement({
  onAddressChanged,
  session = {},
  validator,
  disableField,
  onUpdating,
  survey,
  startHTML,
  endHTML,
  ...content
}) {
  /* Evaluate Conditional States */
  content.state = getConditionalState(
    content.conditionalState,
    session.answers
  );
  return (
    <div
      className="row"
      style={{ display: content.state === "HIDE" ? "none" : "block" }}
    >
      {startHTML && <div dangerouslySetInnerHTML={{ __html: startHTML }} />}
      <RenderContentType
        key={`content-element-${content._id}`}
        session={session}
        validator={validator}
        onAddressChanged={onAddressChanged}
        onUpdating={onUpdating}
        {...content}
        disableField={disableField}
        survey={survey}
      />
      {endHTML && <div dangerouslySetInnerHTML={{ __html: endHTML }} />}
    </div>
  )
}

class Page extends React.Component {
  isDisableField = () => {
    const {
      navigationType,
      responseBySessionAndStatus,
      isEditEnabled,
    } = this.props;
    if (
      navigationType === TABS &&
      responseBySessionAndStatus &&
      responseBySessionAndStatus.sessionId &&
      responseBySessionAndStatus.status === RESPONSE_STATUS_COMPLETED
    ) {
      if (isEditEnabled) {
        return false;
      }
      return true;
    }
    return false;
  };

  render() {
    const {
      children,
      content,
      session,
      onAddressChanged,
      validator,
      onUpdating,
      survey,
      startHTML,
      endHTML
    } = this.props;

    if (validator) {
      validator.purgeFields();
    }
    return (
      <div className="wrapper min-vh-100 mb-5">
        <div
          className="container min-vh-100 py-2 bg-white pt-3 pb-3"
          style={{ padding: "2rem" }}
        >
          {startHTML && <div dangerouslySetInnerHTML={{ __html: startHTML }} />}
          {content
            .map((content, index) =>
              content.hidden ? null : (
                <ContentElement
                  key={`content-element-${content._id}`}
                  session={session}
                  validator={validator}
                  onAddressChanged={onAddressChanged}
                  onUpdating={onUpdating(content.field)}
                  {...content}
                  disableField={this.isDisableField()}
                  survey={survey}
                />
              )
            )
            .filter((c) => c)}
          {endHTML && <div dangerouslySetInnerHTML={{ __html: endHTML }} />}
          {children}
        </div>
      </div>
    );
  }
}

Page.defaultProps = {
  content: [],
  session: {},
};

export default Page;

import { END_USES } from "./end-uses";

// Commercial Buildings Energy Consumption Survey and
// Residential Energy Consumption Survey End Uses Breakdown
export const CBECS_RECS_EUB_COLORS = {
  [END_USES.CLOTHES_DRYER]: "#9C755F",
  [END_USES.CLOTHES_WASHER]: "#D58936",
  [END_USES.COMPUTING]: "#62B58F",
  [END_USES.COOKING]: "#FE7F2D",
  [END_USES.COOLING]: "#425F97",
  [END_USES.DISHWASHER]: "#096B72",
  [END_USES.FANS]: "#9748A3",
  [END_USES.FREEZERS]: "#BC95DF",
  [END_USES.HEATING]: "#E05263",
  [END_USES.HOT_TUB]: "#2E2E3A",
  [END_USES.LIGHTING]: "#FFB30F",
  [END_USES.MICROWAVES]: "#26532B",
  [END_USES.OFFICE_EQUIPMENT]: "#26532B",
  [END_USES.OTHER]: "#858C92",
  [END_USES.POOLS]: "#73D2DE",
  [END_USES.REFRIGERATION]: "#5D62B5",
  [END_USES.TVS]: "#62B58F",
  [END_USES.VENTILATION]: "#9748A3",
  [END_USES.WATER_HEATING]: "#29C3BE",
};

// American Society of Heating, Refrigeration and Air-Conditioning Engineers End Uses Breakdown
export const ASHRAE_EUB_COLORS = {
  [END_USES.SPACE_HEATING]: "#E05263",
  [END_USES.SPACE_COOLING]: "#425F97",
  [END_USES.AIR_DISTRIBUTION]: "#096B72",
  [END_USES.WATER_DISTRIBUTION]: "#73D2DE",
  [END_USES.LIGHTING]: "#FFB30F",
  [END_USES.SHW_DHW]: "#29C3BE",
  [END_USES.VENTILATION]: "#9748A3",
  [END_USES.COOKING]: "#FE7F2D",
  [END_USES.REFRIGERATION]: "#5D62B5",
  [END_USES.CONVEYANCE]: "#9C755F",
  [END_USES.PROCESS_LOADS]: "#BC95DF",
  [END_USES.PLUG_LOADS]: "#26532B",
  [END_USES.IT]: "#62B58F",
  [END_USES.OTHER]: "#858C92",
};

// Water End Uses Breakdown
export const WATER_EUB_COLORS = {
  [END_USES.CLOTHES_WASHERS]: "#9748A3",
  [END_USES.DISHWASHER]: "#096B72",
  [END_USES.FAUCETS]: "#73D2DE",
  [END_USES.LANDSCAPING]: "#425F97",
  [END_USES.LEAKS]: "#2E2E3A",
  [END_USES.OTHER]: "#858C92",
  [END_USES.SHOWER_BATH]: "#5D62B5",
  [END_USES.TOILET]: "#29C3BE",
};

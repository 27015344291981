import React, {useState, useEffect} from "react";
import "./NetworkIndicator.css";

const classType = {
    container: "networkContainer",
    hide: "networkContainerHide",
    connected: "connected",
    disconnected: "disconnected"
};

const NetworkIndicator = ({state}) => {
    const [showIndicator, setShowIndicator] = useState(null);
    useEffect(() => {
        if(showIndicator === null && state.online) {return setShowIndicator(false)};
        setShowIndicator(true)
        if(state.online) {
            setTimeout(() => {
                setShowIndicator(false)
            }, 3000)
        }
    }, [state.online])
    return (
        <div className={`${classType.container} ${state.online ? classType.connected : classType.disconnected} ${showIndicator ? "" : classType.hide}`}>
            <span>{state.online ? "Reconnected to the internet. Syncing your data." : "No internet connection. You can edit fields but they will not be saved until connection has been regained"}</span>
        </div>
    )
}

export default NetworkIndicator;
import React from "react";
import "./Stepper.css";

class Stepper extends React.Component {
  renderChildren() {
    const count = React.Children.count(this.props.children);
    return React.Children.map(this.props.children, (child, index) => {
      return (
        <React.Fragment>
          {child}
          {index < count - 1 && (
            <span
              className="bg-muted"
              style={{ height: "2px", width: "2.5rem" }}
            ></span>
          )}
        </React.Fragment>
      );
    });
  }

  render() {
    return (
      <div className="d-flex justify-content-between align-items-center mobile-scroll" style={{ paddingBottom: "1.5rem" }}>
        {this.renderChildren()}
      </div>
    );
  }
}

export default Stepper;

import React, { useCallback, useContext, useEffect } from 'react';
import './Footer.css';
import { withRouter } from 'react-router';
import { Link, Route, Switch } from 'react-router-dom';
import Button from '../Button/Button';
import qs from 'qs';
import { Mutation } from '@apollo/client/react/components';
import { useMutation } from '@apollo/client';
import {
  CREATE_SESSION,
  updateAfterCreateSession,
} from '../../apis/survey/schema/session';
import SimpleReactValidator from "simple-react-validator";

import { CREATE_RESPONSE } from '../../apis/survey/schema/response';
import {
  SEQUENTIAL,
  RESPONSE_STATUS_COMPLETED,
  TABS,
} from '../../utils/constants';
import useNetwork from '../../utils/useNetwork';
import ConfirmationModal from '../ConfirmationModal/ConfirmationModal';

let defaultProps = {};

const PreviousButton = ({ page_number, match }) => {
  if (page_number > 1) {
    return (
      <Link to={`${match.url}?page=${page_number - 1}`}>
        <Button>PREVIOUS</Button>
      </Link>
    );
  } else {
    return null;
  }
};

const PreviousButtonForTabs = ({ page_number, match, pages }) => {
  const {
    params: { survey_id, session_id },
  } = match;
  if (page_number > 1) {
    return (
      <Link to={`${match.url}?page=${page_number - 1}`}>
        <Button>PREVIOUS</Button>
      </Link>
    );
  } else if (match.path.includes('results') && survey_id && session_id) {
    const url = `/survey/${survey_id}/session/${session_id}?page=${pages.length}`;
    return (
      <Link to={url}>
        <Button>PREVIOUS</Button>
      </Link>
    );
  } else {
    return null;
  }
};

const NextButton = ({
  page_number,
  match,
  pages,
  onClick,
  isDisabled,
  validator,
}) => {
  const networkState = useNetwork();
  if (match.path.includes('results')) {
    return null;
  }
  if (page_number === pages.length) {
    return (
      <Button
        primary
        onClick={onClick}
        isDisabled={isDisabled || !networkState.online}
      >
        SUBMIT
      </Button>
    );
  } else {
    return (
      <Link
        to={`${match.url}?page=${page_number + 1}`}
        onClick={(e) => {
          if (!validator.allValid() || isDisabled) {
            e.preventDefault();
            validator.showMessages();
          } else {
            validator.hideMessages();
          }
        }}
      >
        <Button isDisabled={isDisabled} primary>
          NEXT
        </Button>
      </Link>
    );
  }
};

const StartButton = ({
  survey: { _id, config = {}, uuid } = {},
  history,
  search,
}) => {
  const buildingId =
    qs.parse(search, { ignoreQueryPrefix: true }).building || null;
  const [createSession] = useMutation(CREATE_SESSION, {
    variables: { session: { survey: _id, user: 1, buildingId: buildingId } },
    update: updateAfterCreateSession,
  });
  const loadSessionOnStart = config.startSession === 'ON_LOAD';
  const handleCreateSession = useCallback(async () => {
    const {
      data: {
        createSession: { _id },
      },
    } = await createSession();
    history.push(
      `/survey/${uuid}/session/${_id}${loadSessionOnStart ? '' : '?page=2'}`
    );
  }, [createSession, uuid, history]);

  useEffect(() => {
    if (loadSessionOnStart) {
      handleCreateSession();
    }
  }, []);

  return (
    <Button primary onClick={handleCreateSession}>
      START
    </Button>
  );
};

const NextButtonForTabs = ({
  page_number,
  match,
  pages,
  onClick,
  validator,
  responseBySessionAndStatus,
  isDisabled,
  isEditEnabled,
  handleEditClick,
  allowResubmit,
}) => {
  const networkState = useNetwork();
  let nextButtonUrl = '';
  if (page_number < pages.length && !match.path.includes('results')) {
    nextButtonUrl = `${match.url}?page=${page_number + 1}`;
  } else if (
    !match.path.includes('results') &&
    responseBySessionAndStatus &&
    responseBySessionAndStatus.sessionId &&
    responseBySessionAndStatus.status === RESPONSE_STATUS_COMPLETED &&
    page_number <= pages.length
  ) {
    nextButtonUrl = `${match.url}/results/${responseBySessionAndStatus._id}`;
  }
  return (
    <React.Fragment>
      {nextButtonUrl && (
        <Link to={nextButtonUrl} disabled={isDisabled}>
          <Button primary className='ml-2' isDisabled={isDisabled}>
            NEXT
          </Button>
        </Link>
      )}
      {responseBySessionAndStatus &&
      responseBySessionAndStatus.sessionId &&
      responseBySessionAndStatus.status === RESPONSE_STATUS_COMPLETED &&
      allowResubmit ? (
        <>
          {isEditEnabled ? (
            <Button primary className='ml-2' onClick={onClick}>
              RESUBMIT
            </Button>
          ) : (
            <Button
              primary
              className='ml-2'
              onClick={() => handleEditClick(true)}
            >
              EDIT
            </Button>
          )}
        </>
      ) : (
        <>
          {!match.path.includes('results') && (
            <Button
              primary
              onClick={onClick}
              className='ml-2'
              isDisabled={
                isDisabled ||
                !validator.allValid() ||
                !networkState.online ||
                (responseBySessionAndStatus &&
                  responseBySessionAndStatus.sessionId &&
                  responseBySessionAndStatus.status ===
                    RESPONSE_STATUS_COMPLETED)
              }
            >
              SUBMIT
            </Button>
          )}
        </>
      )}
    </React.Fragment>
  );
};

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showConfirmation: false,
      createResponse: null,
    };
  }

  handleCreateSession(session, createSession) {
    createSession({ variables: { session } }).then(
      ({
        data: {
          createSession: { _id },
        },
      }) => {
        let { uuid } = this.props.survey;
        this.props.history.push(`/survey/${uuid}/session/${_id}?page=2`);
      }
    );
  }

  handleCreateResponse(params, createResponse, validator) {
    const { session_id } = params;
    if (validator.allValid()) {
      createResponse({
        variables: { response: { session: session_id, status: 'PENDING' } },
      }).then(({ data: { createResponse: { _id } } }) => {
        let { uuid } = this.props.survey;
        this.props.history.push(
          `/survey/${uuid}/session/${session_id}/results/${_id}`
        );
      });
    } else {
      validator.showMessages();
    }
  }

  handleShowConfirmation = (createResponse) => {
    this.setState({ showConfirmation: true, createResponse });
  };

  handleSubmitForTabs = () => {
    const {
      match: { params },
      validator,
      handleEditClick,
    } = this.props;
    const { createResponse } = this.state;
    this.handleCreateResponse(params, createResponse, validator);
    this.handleResetShowConfimration();
    handleEditClick(false);
  };

  handleResetShowConfimration = () => {
    this.setState({ showConfirmation: false, createResponse: null });
  };

  registerAllFieldsValidation = () => {
    const {
      validator,
      survey: { pages },
      session: { answers },
    } = this.props;
    validator.purgeFields();
    pages.forEach((page) => {
      const { content } = page;
      content.forEach((c) => {
        const { type, title, format, field } = c;
        let validationOptions = '';
        switch (type) {
          case 'input':
            if (title.startsWith('*')) {
              validationOptions += 'required';
            }
            if (format === 'year') {
              validationOptions += '|min:4';
            }
            validationOptions = validationOptions.replace(/^\|+|\|+$/g, '');
            if (validationOptions) {
              let value = '';
              if (answers && answers.length > 0) {
                const fieldAnswer = answers.find((o) => o.field === field);
                if (fieldAnswer && fieldAnswer.value) {
                  value = fieldAnswer.value;
                }
              }
              validator.message(field, value, validationOptions);
            }
            break;
          case 'select':
            if (title.startsWith('*')) {
              validationOptions += 'required';
            }
            validationOptions = validationOptions.replace(/^\|+|\|+$/g, '');
            if (validationOptions) {
              let value = '';
              if (answers && answers.length > 0) {
                const fieldAnswer = answers.find((o) => o.field === field);
                if (fieldAnswer && fieldAnswer.value) {
                  value = fieldAnswer.value;
                }
              }
              validator.message(field, value, validationOptions);
            }
            break;
          default:
            break;
        }
      });
    });
  };

  isNextButtonDisabled(session = {}) {
    if (session.answers) {
      return session.answers.some(
        (item) => item.validation && !item.validation.isValid
      );
    }
    return true;
  }

  render() {
    const {
      survey: { pages, _id, config = {}, projects = [] },
      session,
      match,
      location: { search },
      validator,
      navigationType,
      history,
      responseBySessionAndStatus,
      isUpdating,
      isEditEnabled,
      handleEditClick,
    } = this.props;
    const page_number = parseInt(
      qs.parse(search, { ignoreQueryPrefix: true }).page || 1
    );
    const { params } = match;
    const { allowResubmit } = config;

    if (navigationType === TABS) {
      this.registerAllFieldsValidation();
    }

    return (
      <footer className='navbar fixed-bottom border bg-white border-light justify-content-end footer-container'>
        <Switch>
          <Route exact path={'/survey/:survey_id'}>
            <StartButton
              survey={this.props.survey}
              history={history}
              search={this.props.location.search}
            />
          </Route>
          <Route path={'/survey/:survey_id/session/:session_id'}>
            <Mutation mutation={CREATE_RESPONSE}>
              {(createResponse, { loading, error }) => {
                if (loading) {
                  return (
                    <Button primary>
                      <div
                        className='spinner-border spinner-border-sm'
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </Button>
                  );
                }
                return (
                  <React.Fragment>
                    {navigationType === SEQUENTIAL && (
                      <PreviousButton {...{ page_number, match }} />
                    )}
                    {navigationType === TABS && (
                      <PreviousButtonForTabs
                        {...{ page_number, match, pages }}
                      />
                    )}
                    {/* <div className="pl-2"> */}
                    {navigationType === SEQUENTIAL && (
                      <NextButton
                        {...{ page_number, match, pages }}
                        onClick={() =>
                          this.handleCreateResponse(
                            params,
                            createResponse,
                            validator
                          )
                        }
                        validator={validator}
                        isDisabled={
                          isUpdating || this.isNextButtonDisabled(session)
                        }
                      />
                    )}
                    {navigationType === TABS && (
                      <>
                        <NextButtonForTabs
                          {...{ page_number, match, pages }}
                          onClick={() =>
                            this.handleShowConfirmation(createResponse)
                          }
                          // onClick={() =>
                          //   this.handleCreateResponse(params, createResponse, validator)
                          // }
                          validator={validator}
                          responseBySessionAndStatus={
                            responseBySessionAndStatus
                          }
                          isDisabled={
                            isUpdating || this.isNextButtonDisabled(session)
                          }
                          isEditEnabled={isEditEnabled}
                          handleEditClick={handleEditClick}
                          allowResubmit={allowResubmit}
                        />
                        <ConfirmationModal
                          show={this.state.showConfirmation}
                          handleClose={this.handleResetShowConfimration}
                          handleSubmit={this.handleSubmitForTabs}
                          title={
                            isEditEnabled
                              ? 'Confirm Resubmit'
                              : 'Confirm your submission'
                          }
                          body={
                            isEditEnabled
                              ? projects && projects.length > 0
                                ? 'Resubmitting the survey will delete all measures added through the original submission'
                                : 'Are you sure you want to resubmit the data?'
                              : 'Are you sure you want to submit the data?'
                          }
                          isEditEnabled={isEditEnabled}
                        />
                      </>
                    )}
                    {/* </div> */}
                  </React.Fragment>
                );
              }}
            </Mutation>
          </Route>
        </Switch>
      </footer>
    );
  }
}

Footer.defaultProps = defaultProps;

export default withRouter(Footer);

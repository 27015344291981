import React from "react";
import propTypes from "prop-types";
import { Link, Route, Switch } from "react-router-dom";
import Stepper from "../Stepper/Stepper";
import Step from "../Step/Step";
import "./PageStepper.scss";

function CompletedStep({ title, label, to }) {
  return (
    <Link to={to}>
      <Step completed label={label}>
        {title}
      </Step>
    </Link>
  );
}

function CurrentStep({ title, label, to, allowNavigation = false }) {
  return (
    <Link to={allowNavigation && to}>
      <Step active label={label}>
        {title}
      </Step>
    </Link>
  );
}

function FutureStep({ title, label }) {
  return <Step label={label}>{title}</Step>;
}

function nextStepUrl(match, pageIndex, hasResults) {
  let baseUrl = match.url;
  if(hasResults) {
    baseUrl = baseUrl.split("/results")[0];
  }
  return `${baseUrl}?page=${pageIndex + 1}`;
}

function PageStepper({ match, pages, page_number, config }) {
  const currentPage = pages[page_number - 1] || null;
  const hasResults = match.path.includes("results");
  return (
    <>
      {currentPage && (
        <div className="page-header-title">{hasResults ? "Results" : currentPage.title}</div>
      )}
      <Stepper>
        {pages.map((page, pageIndex) => {
          if (page_number === pageIndex + 1) {
            return (
              <CurrentStep
                key={`page-${pageIndex + 1}`}
                title={page.title}
                label={pageIndex + 1}
                to={nextStepUrl(match, pageIndex, hasResults)}
                allowNavigation={config?.startSession === "ON_LOAD"}
              />
            );
          } else if (
            page_number > pageIndex + 1 ||
            hasResults
          ) {
            return (
              <CompletedStep
                key={`page-${pageIndex + 1}`}
                title={page.title}
                label={pageIndex + 1}
                to={nextStepUrl(match, pageIndex, hasResults)}
              />
            );
          } else {
            return (
              <FutureStep
                key={`page-${pageIndex + 1}`}
                title={page.title}
                label={pageIndex + 1}
              />
            );
          }
        })}
        <Switch>
          <Route
            path={`/survey/:survey_id/session/:session_id/results/:result_id`}
          >
            <CurrentStep label={pages.length + 1} title="Results" />
          </Route>
          <Route>
            <FutureStep label={pages.length + 1} title="Results" />
          </Route>
        </Switch>
      </Stepper>
    </>
  );
}

PageStepper.propTypes = {
  pages: propTypes.array.isRequired,
};

export default PageStepper;

import React from "react";
import { Mutation } from "@apollo/client/react/components";
import { useHistory } from "react-router";
import {
  CREATE_SESSION,
  updateAfterCreateSession,
} from "../../apis/survey/schema/session";
import "./Tabs.scss";

const Tabs = ({ survey, match, pages, page_number, responseBySessionAndStatus }) => {
  const {
    params: { session_id },
  } = match;
  const history = useHistory();
  if (!pages || pages.length <= 0) {
    return null;
  }

  const handleTabChange = (match, pageIndex, hasResults) => {
    if (!hasResults && page_number === pageIndex + 1) {
      return;
    }
    let baseUrl = match.url;
    if (hasResults) {
      baseUrl = baseUrl.split("/results")[0];
    }
    const url = `${baseUrl}?page=${pageIndex + 1}`;
    history.push(url);
  };

  const handleResultClick = () => {
    if (responseBySessionAndStatus && responseBySessionAndStatus._id) {
      const url = `${match.url}/results/${responseBySessionAndStatus._id}`;
      history.push(url);
    }
  }

  const handleCreateSession = (session, createSession, pageIndex) => {
    createSession({ variables: { session } }).then(
      ({
        data: {
          createSession: { _id },
        },
      }) => {
        let { uuid } = survey;
        history.push(`/survey/${uuid}/session/${_id}?page=${pageIndex + 1}`);
      }
    );
  };

  const hasResults = match.path.includes("results");

  return (
    <div className={`tabs-wrapper`}>
      {session_id ? (
        <div className={`tabs-container`}>
          {pages.map((page, pageIndex) => (
            <div
              key={page._id}
              name={`${page._id}Tab`}
              onClick={() => {
                handleTabChange(match, pageIndex, hasResults);
              }}
              className={`tab ${!hasResults && page_number === pageIndex + 1 ? "active" : ""}`}
            >
              {page.title}
            </div>
          ))}

          <div
            name={`ResultTab`}
            className={`tab ${hasResults ? "active" : ""}`}
            onClick={handleResultClick}
          >
            Results
          </div>
        </div>
      ) : (
        <Mutation
          mutation={CREATE_SESSION}
          update={(...args) => updateAfterCreateSession(...args)}
        >
          {(createSession) => {
            return (
              <div className={`tabs-container`}>
                {pages.map((page, pageIndex) => (
                  <div
                    key={page._id}
                    name={`${page._id}Tab`}
                    onClick={() => {
                      handleCreateSession(
                        { survey: survey._id, user: 1 },
                        createSession,
                        pageIndex
                      );
                    }}
                    className={`tab ${
                      page_number === pageIndex + 1 ? "active" : ""
                    }`}
                  >
                    {page.title}
                  </div>
                ))}

                <div name={`ResultTab`} className={`tab`}>
                  Results
                </div>
              </div>
            );
          }}
        </Mutation>
      )}
    </div>
  );
};

export default Tabs;

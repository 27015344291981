import gql from "graphql-tag";

export const CREATE_RESPONSE = gql`
  mutation createResponse($response: CreateResponseInput!) {
    createResponse(response: $response) {
      _id
      content {
        buildingId
        buildingName
        projects {
          project {
            _id
            name
            displayName
            analysisType
          }
          projectSimulation {
            _id
            result {
              energy_savings {
                electric
                gas
              }
              annual_savings {
                electric_charge
                gas_charge
              }
              complete
            }
          }
        }
      }
    }
  }
`;

export const GET_RESPONSE = gql`
  query Response($response: SearchResponseInput!, $isFirstPoll: Boolean) {
    response(response: $response, isFirstPoll: $isFirstPoll) {
      _id
      sessionId
      status
      content {
        buildingId
        buildingName
        projects {
          project {
            _id
            name
            displayName
            analysisType
            metric {
              projectCost
              annualSavings
              electricSavings
              gasSavings
              ghgSavings
              ghgSavingsCost
              energySavings
              incentive
              simple_payback
              annualElectricSavings
              annualGasSavings
            }
          }
          projectSimulation {
            _id
            baseline
            result {
              energy_savings {
                electric
                gas
              }
              annual_savings {
                electric_charge
                gas_charge
              }
              complete
            }
          }
        }
      }
    }
  }
`;

export const GET_RESPONSE_BY_SESSION_AND_STATUS = gql`
  query Response($response: SearchResponseInputBySessionAndStatus!) {
    responseBySessionAndStatus(response: $response) {
      _id
      sessionId
      status
      content {
        buildingId
        buildingName
        projects {
          project {
            _id
            name
            displayName
            analysisType
            metric {
              projectCost
              annualSavings
              electricSavings
              gasSavings
              ghgSavings
              ghgSavingsCost
              energySavings
              incentive
              simple_payback
              annualElectricSavings
              annualGasSavings
            }
          }
          projectSimulation {
            _id
            baseline
            result {
              energy_savings {
                electric
                gas
              }
              annual_savings {
                electric_charge
                gas_charge
              }
              complete
            }
          }
        }
      }
    }
  }
`;

// export const updateAfterCreateResponse = (
//   cache,
//   { data: { createResponse: response } },
//   variables
// ) => {
//   cache.writeQuery({
//     query: GET_RESPONSE,
//     variables: { response: { _id: response._id } },
//     data: { response }
//   });
// };

import React from 'react';
import { Query, Mutation } from '@apollo/client/react/components';
import { round } from 'lodash';
import { GET_EUB, UPDATE_EUB } from '../../apis/survey/schema/session';
import Donut2DChart from '../Chart/Donut2DChart';
import {
  checkIsResidentalEndUse,
  numberWithCommas,
  hasEndUseDataForFuelTypes,
} from '../../utils/projectHelpers';
import {
  defaultEndUseLabels,
  residentalEndUseLabels,
  residentalWaterEndUseLabels,
} from '../../static/enduse-labels';
import Loader from '../Loader';
import OverviewEndUseEdition from '../OverviewEndUseEdition/OverviewEndUseEdition';
class EndUseBreakdown extends React.Component {
  state = {
    sessionID: '',
    energyTypes: {
      value: 'energyUse',
      options: [
        {
          value: 'energyUse',
          label: 'Energy Use',
        },
        {
          value: 'energyCost',
          label: 'Energy Cost',
        },
        {
          value: 'electricityUse',
          label: 'Electricity Use',
        },
        {
          value: 'electricityCost',
          label: 'Electricity Cost',
        },
        {
          value: 'naturalGasUse',
          label: 'Natural Gas Use',
        },
        {
          value: 'naturalGasCost',
          label: 'Natural Gas Cost',
        },
      ],
    },
    chartConfig: {
      decimals: '2',
      showpercentvalues: '0',
      showLabels: false,
      showValues: '0',
      showVLineLabelBorder: '0',
      showLegend: '1',
      legendPosition: 'top',
      theme: 'fusion',
      legendIconSides: 4,
      legendNumColumns: 2,
      legendItemFontSize: 12,
      showToolTip: true,
      doughnutRadius: 55,
      pieRadius: 70,
    },
    pieData: [],
    editFormData: [],
    currentEndUse: {},
    buildingUse: '',
    touchedKeys: [],
    endUseLabels: [],
    showEditionForm: false,
    totalKbtu: 0,
    elecKbtu: 0,
    gasKbtu: 0,
  };

  inputChangedValue = false;

  componentDidMount() {
    const urlArray = window.location.pathname.split('/');
    const sessionID = urlArray[urlArray.length - 1];
    this.setState({
      sessionID: sessionID,
    });
  }

  onSurveyFetched = (data) => {
    if (!data || !data.getEub) return;
    const buildingUse = data.getEub.endUse.buildingType;
    const isWaterEnergyType = checkIsResidentalEndUse(buildingUse);
    const _energyTypes = this.state.energyTypes;
    const {
      hasElectricData,
      hasNaturalGasData,
      hasWaterData,
    } = hasEndUseDataForFuelTypes(data.getEub.endUse);
    if (isWaterEnergyType) {
      _energyTypes.options = [
        ..._energyTypes.options,
        ...(checkIsResidentalEndUse(buildingUse)
          ? hasWaterData
            ? [
                {
                  value: 'waterUse',
                  label: 'Water Use',
                },
                {
                  value: 'waterCost',
                  label: 'Water Cost',
                },
              ]
            : [
                {
                  value: 'waterUse',
                  label: 'Water Use',
                },
              ]
          : []),
      ];
    }
    if (!hasElectricData)
      _energyTypes.options = _energyTypes.options.filter(
        (option) => !option.value.includes('electricityCost')
      );
    if (!hasNaturalGasData)
      _energyTypes.options = _energyTypes.options.filter(
        (option) => !option.value.includes('naturalGasCost')
      );
    if (!hasNaturalGasData && !hasElectricData) {
      _energyTypes.options = _energyTypes.options.filter(
        (option) => !option.value.includes('energyCost')
      );
    }
    this.setState(
      {
        currentEndUse: data.getEub.endUse,
        buildingUse: buildingUse,
        energyTypes: _energyTypes,
        eubPercentage: this.props.survey?.config?.eubType === 'PERCENT_ONLY',
        hasElectricData,
        hasNaturalGasData,
        hasWaterData,
      },
      function() {
        this.transformEndUseData();
      }
    );
  };

  transformEndUseData = () => {
    const energyType = this.state.energyTypes.value;
    const endUse = this.state.currentEndUse;
    const {
      buildingUse,
      isWaterEnergyType,
      eubPercentage,
      hasElectricData,
      hasNaturalGasData,
      hasWaterData,
    } = this.state;

    let pieArray = [];
    let total = 0;
    let numberPrefix = '';
    let numberSuffix = '';
    let totalKbtu = 0;
    let gasKbtu = 0;
    let elecKbtu = 0;
    let showPercentage = false;
    const isResidentalEndUse = checkIsResidentalEndUse(buildingUse);
    const endUseLabels = isWaterEnergyType
      ? residentalWaterEndUseLabels
      : isResidentalEndUse
      ? residentalEndUseLabels
      : defaultEndUseLabels;

    if (isWaterEnergyType) {
      const waterEndUse = endUse && endUse.water ? endUse.water : {};
      Object.entries(waterEndUse).map(([use, analysis]) => {
        const endUseLabel = endUseLabels.find((o) => o.name === `water-${use}`);
        if (energyType === 'waterUse') {
          numberSuffix = ' ccf';
          showPercentage = !hasWaterData && eubPercentage;
          if (endUseLabel) {
            const value = showPercentage
              ? analysis.pct
              : analysis.estimated_consumption;
            const percentage = analysis.pct;
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value,
              percentage,
            });
          }
        } else if (energyType === 'waterCost') {
          numberPrefix = '$';
          if (endUseLabel) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.estimated_cost,
              percentage: analysis.pct,
            });
          }
        }
        pieArray = pieArray.filter((item) => !!item.value);
      });
    } else {
      Object.entries(endUse).map(([use, analysis]) => {
        const endUseLabel = endUseLabels.find((o) => o.name === use);
        if (energyType === 'energyUse') {
          numberSuffix = ' kBtu';
          showPercentage =
            !hasElectricData && !hasNaturalGasData && eubPercentage;
          if (endUseLabel && analysis.estimated_consumption > 0) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.estimated_consumption,
              percentage: analysis.percentage,
            });
          } else if (endUseLabel && showPercentage) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.percentage,
              percentage: analysis.percentage,
            });
          }
        } else if (energyType === 'energyCost') {
          numberPrefix = '$';
          if (endUseLabel && analysis.percentage && analysis.percentage > 0) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.estimated_cost,
              percentage: analysis.percentage,
            });
          }
        } else if (energyType === 'electricityUse') {
          numberSuffix = ' kWh';
          showPercentage = !hasElectricData && eubPercentage;
          if (endUseLabel) {
            const value = showPercentage
              ? analysis.pct_elec
              : analysis.estimated_consumption_elec;
            const percentage = analysis.pct_elec;
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value,
              percentage,
            });
          }
        } else if (energyType === 'electricityCost') {
          numberPrefix = '$';
          if (endUseLabel) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.estimated_cost_elec,
              percentage: analysis.pct_elec,
            });
          }
        } else if (energyType === 'naturalGasUse') {
          numberSuffix = ' therms';
          showPercentage = !hasNaturalGasData && eubPercentage;
          if (endUseLabel) {
            const value = showPercentage
              ? analysis.pct_gas
              : analysis.estimated_consumption_gas;
            const percentage = analysis.pct_gas;
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value,
              percentage,
            });
          }
        } else if (energyType === 'naturalGasCost') {
          numberPrefix = '$';
          if (endUseLabel) {
            pieArray.push({
              color: endUseLabel.color,
              isActive: true,
              basicLabel: endUseLabel.label,
              label: endUseLabel.label,
              value: analysis.estimated_cost_gas,
              percentage: analysis.pct_gas,
            });
          }
        }
        pieArray = pieArray.filter((item) => !!item.value);
      });
      gasKbtu = endUse
        ? (endUse['gas-energy-estimate']?.estimated_consumption || 0) * 100
        : 0;
      if (gasKbtu === 0 && eubPercentage) {
        gasKbtu = endUse['gas-energy-estimate']?.approx_consumption_kbtu || 0;
      }
      elecKbtu = endUse
        ? (endUse['electric-energy-estimate']?.estimated_consumption || 0) *
          3.4121416331
        : 0;
      if (elecKbtu === 0 && eubPercentage) {
        elecKbtu =
          endUse['electric-energy-estimate']?.approx_consumption_kbtu || 0;
      }
      totalKbtu = gasKbtu + elecKbtu;
    }
    pieArray = pieArray.sort((a, b) => b.value - a.value);
    pieArray.forEach((pieItem) => {
      total += pieItem.value * 1;
    });

    const _pieData = showPercentage
      ? pieArray.map((e) => ({ ...e, value: e.value * 100 }))
      : pieArray;
    this.setState({
      chartConfig: {
        ...this.state.chartConfig,
        numberPrefix: showPercentage ? '' : numberPrefix,
        numberSuffix: showPercentage ? '%' : numberSuffix,
        defaultCenterLabel: !showPercentage
          ? `Total ${numberPrefix}${numberWithCommas(
              total.toFixed()
            )}${numberSuffix}`
          : '',
      },
      totalVal: total,
      totalKbtu: totalKbtu,
      elecKbtu: elecKbtu,
      gasKbtu: gasKbtu,
      isResidentalEndUse: isResidentalEndUse,
      endUseLabels: endUseLabels,
      pieData: _pieData,
      disabledInputs: false,
      showEditionForm: true,
    });

    if (pieArray.length > 0) {
      this.createEditFromData(pieArray, endUseLabels);
    } else {
      this.setState({
        editFormData: [],
      });
    }
  };

  createEditFromData = (pieArray, endUseLabels) => {
    let editFormData = [];
    endUseLabels.forEach((el) => {
      const pieData = pieArray.find((o) => o.basicLabel === el.label);
      if (pieData) {
        editFormData.push({
          isActive: true,
          label: el.label,
          value: round(pieData.percentage * 100, 0),
        });
      } else {
        editFormData.push({
          isActive: false,
          label: el.label,
          value: 0,
        });
      }
    });

    editFormData = editFormData.sort((a, b) => b.value - a.value);
    this.setState({
      editFormData: editFormData,
      showEditionForm: true,
    });
  };

  handleChangeEnergyDropdown = (e) => {
    this.setState({
      showEditionForm: false,
    });
    const energyType = e.target.value;
    const isWaterEnergyType = ['waterUse', 'waterCost'].includes(energyType);
    this.setState(
      {
        energyTypes: {
          ...this.state.energyTypes,
          value: energyType,
        },
        isWaterEnergyType,
      },
      () => {
        this.transformEndUseData();
      }
    );
  };

  createEndUseConfiguration = (type, percentage, editFormData, endUse) => {
    const {
      isResidentalEndUse,
      endUseLabels,
      energyTypes: { value: energyType },
      isWaterEnergyType,
    } = this.state;
    const endUseConfiguration = {};
    for (let editForm of editFormData) {
      let { label = '', value = 0 } = editForm;
      const endUseLabel = endUseLabels.find((o) => o.label === label).name;
      let name = label.toLowerCase();
      if (label === 'Office Equipment') {
        name = 'office';
      } else if (label === 'Water Heating') {
        if (isResidentalEndUse) name = 'hw';
        else name = 'dhw';
      }

      if (type === 'reset') {
        if (isWaterEnergyType) {
          endUseConfiguration[endUseLabel] = {
            percentage: null,
            pct: null,
          };
        } else {
          endUseConfiguration[name] = {
            percentage: null,
            pct_elec: null,
            pct_gas: null,
          };
        }
      } else {
        if (isWaterEnergyType) {
          const [parentKey, subKey] = endUseLabel.split('-');
          if (endUse?.[parentKey]?.[subKey]) {
            endUseConfiguration[endUseLabel] = {
              percentage: endUse[parentKey][subKey]?.percentage || 0,
              pct:
                (percentage ? value / 100 : endUse[parentKey][subKey]?.pct) ||
                0,
            };
          }
        } else {
          endUseConfiguration[name] = {
            percentage:
              (percentage && energyType === 'energyUse'
                ? value / 100
                : endUse[endUseLabel]?.percentage) || 0,
            pct_elec:
              (percentage && energyType === 'electricityUse'
                ? value / 100
                : endUse[endUseLabel]?.pct_elec) || 0,
            pct_gas:
              (percentage && energyType === 'naturalGasUse'
                ? value / 100
                : endUse[endUseLabel]?.pct_gas) || 0,
          };
        }
      }
    }
    if (isWaterEnergyType) {
      return Object.entries(endUseConfiguration).reduce((accum, current) => {
        accum[current[0].replace('-', '_')] = current[1];
        return accum;
      }, {});
    }
    return endUseConfiguration;
  };

  handleSaveEditForm = (data, updateEub, endUse) => {
    const { sessionID } = this.state;
    const endUseConfiguration = this.createEndUseConfiguration(
      'save',
      true,
      data,
      endUse
    );
    updateEub({
      variables: {
        session: { _id: sessionID },
        endUseConfiguration: endUseConfiguration,
      },
    }).then(({ data }) => {
      console.log('----------------------', data);
      this.setState(
        {
          currentEndUse: data.updateEub.endUse,
          showEditionForm: false,
        },
        () => {
          this.transformEndUseData();
        }
      );
    });
  };

  handleCloseEditForm = (data) => {
    this.setState({
      editFormData: data,
    });
  };

  handleResetEditForm = async (updateEub) => {
    const { sessionID } = this.state;
    this.setState(
      {
        touchedKeys: [],
        showEditionForm: false,
        disabledInputs: true,
      },
      () => {
        try {
          const endUseConfiguration = this.createEndUseConfiguration(
            'reset',
            false,
            this.state.editFormData,
            this.state.currentEndUse
          );
          updateEub({
            variables: {
              session: { _id: sessionID },
              endUseConfiguration: endUseConfiguration,
            },
          }).then(({ data }) => {
            console.log('----------------------', data);
            this.setState(
              {
                currentEndUse: data.updateEub.endUse,
              },
              () => {
                this.transformEndUseData();
              }
            );
          });
        } catch (error) {
          console.log('error', error);
          this.setState({
            disabledInputs: true,
            showEditionForm: true,
          });
        }
      }
    );
  };

  onMouseDown = (event) => {
    event.stopPropagation();
  };

  onMouseUp = (event) => {
    event.stopPropagation();
  };

  render() {
    const {
      pieData,
      editFormData,
      energyTypes,
      sessionID,
      disabledInputs,
      showEditionForm,
    } = this.state;
    return (
      <Query
        query={GET_EUB}
        skip={!sessionID}
        fetchPolicy={'network-only'}
        onCompleted={this.onSurveyFetched}
        variables={{ session: { _id: sessionID } }}
      >
        {({ loading: eubLoading, data: { endUse } = {} }) => {
          return (
            <Mutation mutation={UPDATE_EUB}>
              {(updateEub, { loading, error }) => {
                return (
                  <div className='row'>
                    {eubLoading || loading ? (
                      <div className='col-md-6'>
                        <Loader />
                      </div>
                    ) : (
                      <div className='col-md-6'>
                        <div className='row'>
                          <div className='col-md-6 selectContainer-wrapper'>
                            <div className='selectContainer'>
                              <select
                                onChange={(e) =>
                                  this.handleChangeEnergyDropdown(e)
                                }
                                value={energyTypes.value}
                                onMouseDown={this.onMouseDown}
                                onMouseUp={this.onMouseUp}
                                disabled={disabledInputs}
                              >
                                <option defaultValue value='' disabled>
                                  Select Energy Type
                                </option>
                                {energyTypes.options.map((item, i) => (
                                  <option key={item.value} value={item.value}>
                                    {item.label}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </div>
                          <div className='col-md-6'></div>
                        </div>
                        <Donut2DChart
                          chartConfig={this.state.chartConfig}
                          data={pieData}
                          height={400}
                        />
                      </div>
                    )}
                    <div className='col-md-6'>
                      {editFormData.length > 0 && showEditionForm && (
                        <OverviewEndUseEdition
                          initialData={editFormData}
                          initialEndUse={this.state.currentEndUse}
                          endUseLabels={this.state.endUseLabels}
                          energyType={this.state.energyTypes.value}
                          totalKbtu={this.state.totalKbtu}
                          elecKbtu={this.state.elecKbtu}
                          gasKbtu={this.state.gasKbtu}
                          disabled={disabledInputs || eubLoading || loading}
                          onSave={(data, endUse) =>
                            this.handleSaveEditForm(data, updateEub, endUse)
                          }
                          onClose={this.handleCloseEditForm}
                          onReset={() => this.handleResetEditForm(updateEub)}
                        />
                      )}
                    </div>
                  </div>
                );
              }}
            </Mutation>
          );
        }}
      </Query>
    );
  }
}

export default EndUseBreakdown;

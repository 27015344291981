import React, { useState, useCallback } from 'react';
import ReactQuill from 'react-quill';
import { Formik, Field } from "formik";
import { ObjectId } from 'bson';
import { Mutation } from "@apollo/client/react/components";
import {
  ADD_SESSION_ANSWER,
  UPDATE_SESSION_ANSWER,
  updateAfterAddSessionAnswer,
  updateAfterUpdateSessionAnswer,
} from "../../apis/survey/schema/session";
import ToolTip from "../ToolTip";
import 'react-quill/dist/quill.snow.css';
import {debounce} from 'lodash';
import {generateOptimisticResponse} from '../../utils/question';

const TextEditor = ({content, answer, session, disableField, onUpdating}) => {
    const { title, body, field, format, state, infoField = "", initialValue = "" } = content
    let mutation,
      updateAfterMutation,
      updateVariables = { session: { _id: session._id } };
    if (answer?._id) {
      //Not new, send update
      mutation = UPDATE_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterUpdateSessionAnswer(...args, updateVariables);
    } else {
      //Is new, send create
      mutation = ADD_SESSION_ANSWER;
      updateAfterMutation = (...args) =>
        updateAfterAddSessionAnswer(...args, updateVariables);
    }
    const [value, setValue] = useState(answer?._id ? answer.value : initialValue);
    const handleSubmit = useCallback(debounce(async (executeMutation, newValue)  => {
        setValue(newValue);
        try {
          onUpdating(true);
          if (answer?._id) {
            const optimisticResponse = generateOptimisticResponse(answer, session, newValue, 'updateAnswer')
            //Not new, send update
            await executeMutation({
              optimisticResponse,
              variables: {
                session: { _id: session._id },
                answer: {
                  _id: answer._id,
                  value: newValue,
                },
              },
            });
          } else {
            const newAnswer = {
                value: newValue,
                field,
                question: content._id,
                _id: (new ObjectId()).toString()
            }
            const optimisticResponse = generateOptimisticResponse(newAnswer, session, newValue, 'addAnswer')
            //Is new, send create
            await executeMutation({
              optimisticResponse,
              variables: {
                session: { _id: session._id },
                answer: newAnswer,
              },
            });
          }
        } finally {
          onUpdating(false);
        }
    }, 1000), [content._id, field, answer, session, onUpdating, setValue])
    return (
        <Mutation mutation={mutation} update={updateAfterMutation}>
        {(executeMutation, { data, loading }) => {
          return (
            <form
                className="form-group"
                style={{ display: state === "HIDE" ? "none" : "block" }}
            >
                <label htmlFor={field}>
                {title}
                {infoField && (
                    <ToolTip
                    content={<span>{infoField}</span>}
                    direction="right"
                    >
                    <i className="bi bi-info-circle icon"></i>
                    </ToolTip>
                )}
                </label>
                <ReactQuill modules={{toolbar: [['bold', 'italic', 'underline', 'strike'], [{ 'list': 'ordered'}, { 'list': 'bullet' }]]}} theme="snow" value={value} onChange={setValue} readOnly={disableField} onChange={(newValue) => handleSubmit(executeMutation, newValue)}/>
            </form>
          );
        }}
      </Mutation>
    )
}

export default TextEditor;

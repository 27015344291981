import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { round, cloneDeep, sum } from 'lodash';
import Button from '../Button/Button';
import {
  checkZeroNaturalGas,
  isWaterEnergyType,
  isElectricityEnergyType,
  isNaturalGasEnergyType,
  shiftZeroNaturalGasDataToTheEnd,
} from '../../utils/projectHelpers';
import './OverviewEndUseEdition.css';

function OverviewEndUseEdition({
  initialData,
  initialEndUse = {},
  endUseLabels,
  energyType,
  totalKbtu,
  elecKbtu,
  gasKbtu,
  disabled,
  onSave,
  onClose,
  onReset,
}) {
  const [data, setData] = useState(
    shiftZeroNaturalGasDataToTheEnd(initialData, energyType)
  );
  const [previousData, setPreviousData] = useState(initialData);
  const [lockedLabels, setLockedLabels] = useState([]);
  const [endUse, setEndUse] = useState(initialEndUse);
  const inputChangedValue = useRef(false);

  const handleSave = () => {
    onSave(data, endUse);
  };

  const handleClose = () => {
    setData(initialData);
    setPreviousData(initialData);
    onClose(initialData);
  };

  const handleReset = () => {
    setData(initialData);
    setPreviousData(initialData);
    onReset();
  };

  const handleToggleCheck = (index) => {
    const newData = cloneDeep(data);
    newData[index].isActive = !data[index].isActive;
    if (newData[index].isActive) {
      const newLockedLabels = lockedLabels.filter(
        (lockedLabel) => lockedLabel !== newData[index].label
      );
      setLockedLabels(newLockedLabels);
      setData(newData);
    } else {
      newData[index].value = 0;
      handleBlur(index, newData);
    }
  };

  const handleChange = (event, index) => {
    const newData = cloneDeep(data);
    let newValue = Number(event.target.value);
    if (event.target.value.length > 1 && event.target.value[0] === '0') {
      event.target.value = newValue.toString();
    }
    if (newValue > 100 || newValue < 0 || isNaN(newValue)) {
      newValue = 0;
    }
    if (newValue !== data[index]?.value) {
      inputChangedValue.current = true;
    }
    newData[index].value = newValue;
    setData(newData);
  };

  const handleLock = (label, isLocked) => {
    const modifiableData = data.filter((item) => item.isActive);
    const modifiableDataLockedLabels = lockedLabels.filter((lockedLabel) =>
      modifiableData.some((item) => item.label === lockedLabel)
    );
    if (
      isLocked ||
      modifiableDataLockedLabels.length + 1 < modifiableData.length
    ) {
      const newLockedLabels = isLocked
        ? lockedLabels.filter((lockedLabel) => lockedLabel !== label)
        : [...lockedLabels, label];
      setLockedLabels(newLockedLabels);
    }
  };

  const handleOnKeyPress = (event) => {
    if (!/[0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };

  const calculateModifiableValuesSum = (data, valueIndex) => {
    return data.reduce(
      (acc, elem, index) => {
        const shouldIncludeInSum =
          index !== valueIndex &&
          elem.isActive &&
          !lockedLabels.includes(elem.label);
        if (shouldIncludeInSum) {
          return {
            modifiableValuesSum: acc.modifiableValuesSum + elem.value,
            nOfModifiableValues: acc.nOfModifiableValues + 1,
          };
        }
        return acc;
      },
      { modifiableValuesSum: 0, nOfModifiableValues: 0 }
    );
  };

  const validateNewValue = (newValue, label, data, index) => {
    const previousValue = previousData.find((e) => e.label === label)?.value;
    const initialValue = initialData.find((e) => e.label === label)?.value;
    const oldValue = previousValue ?? initialValue ?? 0;
    if (oldValue === newValue) {
      setData(data);
      return false;
    }
    if (isNaturalGasEnergyType(energyType) && checkZeroNaturalGas(label)) {
      alert(
        `Some natural gas end use percentage values cannot be changed and must remain 0.`
      );
      const newData = cloneDeep(data);
      newData[index].value = oldValue;
      setData(newData);
      return false;
    }
    const {
      modifiableValuesSum,
      nOfModifiableValues,
    } = calculateModifiableValuesSum(data, index);
    const valueChange = newValue - oldValue;
    if (modifiableValuesSum - 1 * nOfModifiableValues < valueChange) {
      alert(`Can't change to ${newValue} with current configuration.`);
      const newData = cloneDeep(data);
      newData[index].value = oldValue;
      setData(newData);
      return false;
    }
    return true;
  };

  const calculateNewPctValues = (
    value,
    oldPercentage,
    oldPctElec,
    oldPctGas,
    totalKbtu,
    elecKbtu,
    gasKbtu,
    isZeroNaturalGas
  ) => {
    let newPctElec = 0;
    let newPctGas = 0;
    if (oldPercentage === 0) {
      if (isZeroNaturalGas) {
        if (elecKbtu === 0) {
          newPctElec = 0;
        } else {
          newPctElec = (value * totalKbtu) / elecKbtu;
        }
        newPctGas = 0;
      } else {
        newPctElec = value;
        newPctGas = value;
      }
    } else {
      if (oldPctGas === 0) {
        if (elecKbtu === 0) {
          newPctElec = 0;
        } else {
          newPctElec = (value * totalKbtu) / elecKbtu;
        }
      } else {
        newPctElec = (value * oldPctElec) / oldPercentage;
      }
      if (oldPctElec === 0) {
        if (gasKbtu === 0) {
          newPctGas = 0;
        } else {
          newPctGas = (value * totalKbtu) / gasKbtu;
        }
      } else {
        newPctGas = (value * oldPctGas) / oldPercentage;
      }
    }
    if (newPctElec > 1) {
      newPctElec = 1;
    }
    if (newPctGas > 1) {
      newPctGas = 1;
    }
    newPctElec = round(newPctElec, 2);
    newPctGas = round(newPctGas, 2);

    return [newPctElec, newPctGas];
  };

  const getEndUseName = (label) => {
    return endUseLabels.find((endUseLabel) => endUseLabel.label === label)
      ?.name;
  };

  const calculateNewEndUse = (
    endUse,
    oldValue,
    newValue,
    type,
    workArray,
    endUseName,
    currentAssignedVal = 0
  ) => {
    let newEndUse = cloneDeep(endUse);
    if (type === 'pct_gas') {
      workArray = workArray.filter((elem) => !checkZeroNaturalGas(elem.label));
    }
    // Assign new value
    if (isWaterEnergyType(energyType)) {
      const [key, subKey] = endUseName.split('-');
      newEndUse[key][subKey][type] = newValue;
    } else {
      newEndUse[endUseName][type] = newValue;
    }

    let diffFrom100 = round((oldValue - newValue) * 100, 0);
    let valuesLimit = 1;
    let { sumOfModifiableValues, nOfModifiableValues } = workArray.reduce(
      (acc, elem) => {
        return {
          sumOfModifiableValues: acc.sumOfModifiableValues + elem.value,
          nOfModifiableValues: acc.nOfModifiableValues + 1,
        };
      },
      { sumOfModifiableValues: 0, nOfModifiableValues: 0 }
    );

    // Check if it is possible assign values > 1 if not, let the other values go to 0
    if (
      sumOfModifiableValues - 1 * nOfModifiableValues <
      Math.abs(diffFrom100)
    ) {
      valuesLimit = 0;
    }

    // Assign modifiable values depending on the new value change
    sumOfModifiableValues = 0;
    let numberOfLoop = 0;
    while (diffFrom100 !== 0) {
      let tempDiffFrom100 = diffFrom100;
      let tempSumOfModifiableValues = 0;
      workArray.forEach((elem) => {
        const endUseName = getEndUseName(elem.label);
        let newElemValue = 0;
        if (isWaterEnergyType(energyType)) {
          const [key, subKey] = endUseName.split('-');
          newElemValue = round(
            (newEndUse?.[key]?.[subKey]?.[type] || 0) * 100,
            0
          );
        } else {
          newElemValue = round(newEndUse[endUseName][type] * 100, 0);
        }

        if (tempDiffFrom100 > 0 && tempDiffFrom100 !== 0) {
          // Add to other values that are < 100
          if (newElemValue < 100) {
            newElemValue += 1;
            tempDiffFrom100 -= 1;
          }
        } else if (tempDiffFrom100 < 0 && tempDiffFrom100 !== 0) {
          // Subtract from values that are > 1
          if (newElemValue > valuesLimit) {
            newElemValue -= 1;
            tempDiffFrom100 += 1;
          }
        }
        // Assign new values
        if (isWaterEnergyType(energyType)) {
          const [key, subKey] = endUseName.split('-');
          newEndUse[key][subKey][type] = round(newElemValue / 100, 2);
        } else {
          newEndUse[endUseName][type] = round(newElemValue / 100, 2);
        }
        tempSumOfModifiableValues += newElemValue;
      });
      diffFrom100 = tempDiffFrom100;
      sumOfModifiableValues = tempSumOfModifiableValues;

      // Mechanism to not crash app
      if (numberOfLoop > 100) {
        diffFrom100 = 0;
      }
      numberOfLoop++;
    }
    return newEndUse;
  };

  const calculateNewData = (endUse, data, type, touchedAndLockedKeys) => {
    let newEndUse = cloneDeep(endUse);
    let newData = cloneDeep(data);

    let totalPercentage = 0;
    newData.forEach((elem, idx) => {
      if (elem.isActive) {
        const endUseName = getEndUseName(elem.label);
        let newPctElec = 0;
        let newPctGas = 0;
        let newPctWater = 0;

        if (isWaterEnergyType(energyType)) {
          const [key, subKey] = endUseName.split('-');
          newPctWater = round((newEndUse[key][subKey].pct || 0) * 100, 0);
        } else {
          newPctElec = round(newEndUse[endUseName].pct_elec * 100, 0);
          newPctGas = round(newEndUse[endUseName].pct_gas * 100, 0);
        }
        const newPercentage =
          totalKbtu > 0
            ? (newPctElec * elecKbtu + newPctGas * gasKbtu) / totalKbtu
            : (type === 'percentage'
                ? elem.value
                : newEndUse[endUseName]?.percentage) || 0;

        if (type === 'percentage') {
          if (!touchedAndLockedKeys.includes(idx))
            elem.value = round(newPercentage, 0);
          totalPercentage += elem.value;
        } else if (type === 'pct_elec') {
          elem.value = newPctElec;
        } else if (type === 'pct_gas') {
          elem.value = newPctGas;
        } else if (type === 'pct') {
          elem.value = newPctWater;
        }

        // Update total percentage
        if (isWaterEnergyType(energyType)) {
          const [key, subKey] = endUseName.split('-');
          newEndUse[key][subKey].percentage = round(newPctWater / 100, 2);
        } else {
          newEndUse[endUseName].percentage = round(newPercentage / 100, 2);
        }
      }
    });
    if (type === 'percentage') {
      if (totalPercentage > 100) {
        let dif = round(totalPercentage - 100, 0);
        for (let i = 0; i < newData.length; i++) {
          const el = newData[i];
          const endUseName = getEndUseName(el.label);
          if (!touchedAndLockedKeys.includes(i)) {
            if (round(newEndUse[endUseName][type] * 100, 2) >= dif) {
              newEndUse[endUseName][type] -= round(dif / 100, 2);
              el.value -= dif;
              break;
            } else {
              newEndUse[endUseName][type] = 0;
              el.value = 0;
              dif = dif - round(newEndUse[endUseName][type] * 100, 2);
            }
          }
        }
      } else if (totalPercentage < 100) {
        let dif = round(100 - totalPercentage, 0);
        for (let i = 0; i < newData.length; i++) {
          const el = newData[i];
          const endUseName = getEndUseName(el.label);
          if (el.isActive && !touchedAndLockedKeys.includes(i)) {
            newEndUse[endUseName][type] += round(dif / 100, 2);
            el.value += dif;
            break;
          }
        }
      }
    }
    newData.sort((e1, e2) => e2.value - e1.value);
    return [newData, newEndUse];
  };

  const handleBlur = (index, data) => {
    let { label = '', value = 0 } = data[index];

    // If value is not changed, or is not consistent with other values return
    if (!validateNewValue(value, label, data, index)) return;

    const lockedKeys = lockedLabels.map((label) =>
      data.findIndex((item) => item.label === label)
    );
    let touchedAndLockedKeys = [...lockedKeys];
    if (!touchedAndLockedKeys.includes(index)) {
      touchedAndLockedKeys.push(index);
    }
    const isZeroNaturalGas = checkZeroNaturalGas(label);
    const endUseName = getEndUseName(label);
    const isWater = isWaterEnergyType(energyType);

    // Save old end use percentages
    let oldPercentage = 0;
    let oldPctElec = 0;
    let oldPctGas = 0;
    let oldPctWater = 0;
    if (isWater) {
      const [key, subKey] = endUseName.split('-');
      oldPercentage = endUse?.[key]?.[subKey]?.percentage || 0;
      oldPctWater = endUse?.[key]?.[subKey]?.pct || 0;
    } else {
      oldPercentage = endUse[endUseName].percentage;
      oldPctElec = endUse[endUseName].pct_elec;
      oldPctGas = endUse[endUseName].pct_gas;
    }

    let currentAssignedElectricValue = 0;
    let currentAssignedGasValue = 0;
    let currentAssignedWaterValue = 0;
    let workArray = [];
    data.forEach((elem, elemIndex) => {
      // Calculate sum of locked values
      if (touchedAndLockedKeys.includes(elemIndex) && elemIndex !== index) {
        const endUseName = getEndUseName(elem.label);
        if (isWater) {
          const [key, subKey] = endUseName.split('-');
          currentAssignedWaterValue += endUse?.[key]?.[subKey]?.pct || 0;
        } else {
          currentAssignedElectricValue += endUse[endUseName].pct_elec;
          currentAssignedGasValue += endUse[endUseName].pct_gas;
        }
      }
      // Create array of modifiable values
      if (!touchedAndLockedKeys.includes(elemIndex) && elem.isActive) {
        const shouldSkipEntry =
          (energyType === 'energyUse' || energyType === 'energyCost') &&
          checkZeroNaturalGas(elem.label) &&
          elecKbtu === 0 &&
          elem.value === 0;
        if (shouldSkipEntry) {
          return;
        }
        workArray.push(elem);
      }
    });

    value = round(value / 100, 2);

    let energyValueType;
    let oldPctEnergy;
    let currentAssignedEnergyValue;
    if (isElectricityEnergyType(energyType)) {
      energyValueType = 'pct_elec';
      currentAssignedEnergyValue = currentAssignedElectricValue;
      oldPctEnergy = oldPctElec;
    } else if (isNaturalGasEnergyType(energyType)) {
      energyValueType = 'pct_gas';
      currentAssignedEnergyValue = currentAssignedGasValue;
      oldPctEnergy = oldPctGas;
    } else if (isWaterEnergyType(energyType)) {
      energyValueType = 'pct';
      currentAssignedEnergyValue = currentAssignedWaterValue;
      oldPctEnergy = oldPctWater;
    }
    let newEndUse = endUse;
    let newData = data;
    if (energyValueType) {
      newEndUse = calculateNewEndUse(
        newEndUse,
        oldPctEnergy,
        value,
        energyValueType,
        workArray,
        endUseName,
        currentAssignedEnergyValue
      );
      [newData, newEndUse] = calculateNewData(
        newEndUse,
        newData,
        energyValueType,
        touchedAndLockedKeys
      );
      setEndUse(newEndUse);
      setData(newData);
      setPreviousData(newData);
    } else {
      const [newPctElec, newPctGas] = calculateNewPctValues(
        value,
        oldPercentage,
        oldPctElec,
        oldPctGas,
        totalKbtu,
        elecKbtu,
        gasKbtu,
        isZeroNaturalGas
      );
      if (oldPctElec !== newPctElec) {
        newEndUse = calculateNewEndUse(
          newEndUse,
          oldPctElec,
          newPctElec,
          'pct_elec',
          workArray,
          endUseName,
          currentAssignedElectricValue
        );
        [newData, newEndUse] = calculateNewData(
          newEndUse,
          newData,
          'percentage',
          touchedAndLockedKeys
        );
        setEndUse(newEndUse);
        setData(newData);
        setPreviousData(newData);
      }
      if (oldPctGas !== newPctGas) {
        newEndUse = calculateNewEndUse(
          newEndUse,
          oldPctGas,
          newPctGas,
          'pct_gas',
          workArray,
          endUseName,
          currentAssignedGasValue
        );
        [newData, newEndUse] = calculateNewData(
          newEndUse,
          newData,
          'percentage',
          touchedAndLockedKeys
        );
        setEndUse(newEndUse);
        setData(newData);
        setPreviousData(newData);
      }
    }
  };

  const activeValues = data.filter((item) => item.isActive);
  const activeValuesLocked = lockedLabels.filter((lockedLabel) =>
    activeValues.some((item) => item.label === lockedLabel)
  );
  const canNotLockMore = activeValuesLocked.length + 1 >= activeValues.length;

  return (
    <div className='editForm'>
      <div className='text-right mb-4'>
        <Button onClick={handleSave} primary className='mr-2'>
          Ok
        </Button>
        <Button onClick={handleClose} className='mr-2'>
          Cancel
        </Button>
        <Button onClick={handleReset} primary>
          Reset
        </Button>
      </div>
      <>
        {data.map(({ label, value, isActive }, index) => {
          const isLocked = lockedLabels.includes(label);
          const isZeroNaturalGas =
            isNaturalGasEnergyType(energyType) && checkZeroNaturalGas(label);
          const lastUnlocked = isActive && !isLocked && canNotLockMore;

          return (
            <div key={label} className='editFormRow'>
              <div className='d-flex align-items-center'>
                <input
                  type='checkbox'
                  onChange={() => handleToggleCheck(index)}
                  value={isActive}
                  checked={isActive}
                  className='mr-2'
                  disabled={lastUnlocked || isZeroNaturalGas || disabled}
                />
                <span>{label}</span>
              </div>

              <div className='d-flex align-items-center justify-content-space-between'>
                <input
                  onChange={(event) => handleChange(event, index)}
                  onBlur={() => {
                    if (inputChangedValue.current) {
                      handleBlur(index, data);
                    }
                    inputChangedValue.current = false;
                  }}
                  onKeyPress={handleOnKeyPress}
                  value={value}
                  max={100}
                  type='number'
                  min='0'
                  step='1'
                  disabled={
                    !isActive || lastUnlocked || isZeroNaturalGas || disabled
                  }
                  className='valueBox mr-2 text-center'
                />{' '}
                %
                <button
                  className={`lockButton ${
                    isLocked ? 'lockButtonChecked ' : ''
                  }${
                    !isActive || lastUnlocked || isZeroNaturalGas || disabled
                      ? 'hideLock'
                      : ''
                  }`}
                  onClick={() => handleLock(label, isLocked)}
                  value={isLocked}
                  disabled={
                    !isActive || lastUnlocked || isZeroNaturalGas || disabled
                  }
                >
                  <span className='material-icons lock'>lock</span>
                </button>
              </div>
            </div>
          );
        })}
      </>
    </div>
  );
}

OverviewEndUseEdition.propTypes = {
  initialData: PropTypes.array,
  initialEndUse: PropTypes.object,
  energyType: PropTypes.string,
  endUseLabels: PropTypes.array,
  totalKbtu: PropTypes.number,
  elecKbtu: PropTypes.number,
  gasKbtu: PropTypes.number,
  disabled: PropTypes.bool,
  onSave: PropTypes.func,
  onClose: PropTypes.func,
  onReset: PropTypes.func,
};

export default OverviewEndUseEdition;

import React, { useEffect } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
import { Mutation, Query } from "@apollo/client/react/components";
import qs from "qs";
import Nav from "../Nav/Nav";
import Footer from "../Footer/Footer";
import Page from "../Page/Page";
import Results from "../Results/Results";
import "./Survey.css";
import { GET_SURVEY } from "../../apis/survey/schema/survey";
import {
  GET_SESSION,
  CREATE_SESSION,
  updateAfterCreateSession,
} from "../../apis/survey/schema/session";
import SimpleReactValidator from "simple-react-validator";
import {
  SEQUENTIAL,
  RESPONSE_STATUS_COMPLETED,
  TABS,
} from "../../utils/constants";
import { GET_RESPONSE_BY_SESSION_AND_STATUS } from "../../apis/survey/schema/response";
import Loader from "../Loader";

class Survey extends React.Component {
  state = {
    updateStatus: {},
    isUpdating: false,
    isEditEnabled: false,
  };
  constructor(props) {
    super(props);
    this.validator = new SimpleReactValidator({
      messages: {
        required: 'This field is required',
        yearBuilt: "The build year is required",
      },
      autoForceUpdate: this,
    });
  }

  onSurveyFetched = (data) => {
    if (!data || !data.survey) return;
    const { survey } = data;
    this.applyBrandTheme(survey.theme, survey.name);
  };

  applyBrandTheme = (theme, title) => {
    if (!theme) return;
    const { primaryColor, secondaryColor } = theme;
    document.documentElement.style.setProperty("--primary-color", primaryColor);
    document.documentElement.style.setProperty(
      "--secondary-color",
      secondaryColor
    );
    document.title = title;
    if (theme.faviconSrc) {
      var link = document.querySelector("link[rel~='icon']");
      if (!link) {
        link = document.createElement("link");
        link.rel = "icon";
        document.getElementsByTagName("head")[0].appendChild(link);
      }
      link.href = theme.faviconSrc;
    }
  };

  onUpdating = (field) => (status) => {
    const {updateStatus} = this.state;
    const newUpdatedStatus = {
      ...updateStatus,
      [field]: status
    }
    const isUpdating = Object.values(newUpdatedStatus).some(item => item)
    this.setState({ isUpdating, updateStatus: newUpdatedStatus });
  };

  handleEditClick = (flag = false) => {
    this.setState({ isEditEnabled: flag });
  };

  render() {
    const {
      match: { params, path },
      location: { search },
    } = this.props;
    const { isUpdating, isEditEnabled } = this.state;
    const page_number = parseInt(
      qs.parse(search, { ignoreQueryPrefix: true }).page || 1
    );
    const buildingId =
      qs.parse(search, { ignoreQueryPrefix: true }).building || null;
    return (
      <Query
        query={GET_SURVEY}
        onCompleted={this.onSurveyFetched}
        variables={{ survey: { uuid: params.survey_id } }}
      >
        {({ loading, data: { survey } = {} }) => {
          const navigationType =
            survey &&
            survey.config &&
            survey.config.pageNavigationType &&
            survey.config.pageNavigationType === TABS
              ? TABS
              : SEQUENTIAL;
          if (loading) return <div></div>;
          if (
            !loading &&
            !params.session_id &&
            survey &&
            survey.config &&
            survey.config.startSession &&
            survey.config.startSession === "ON_LOAD"
          ) {
            return (
              <Mutation
                mutation={CREATE_SESSION}
                update={updateAfterCreateSession}
              >
                {(createSession) => {
                  return (
                    <CreateSessionOnLoad
                      createSession={createSession}
                      survey={survey}
                      buildingId={buildingId}
                      uuid={params.survey_id}
                    />
                  );
                }}
              </Mutation>
            );
          }
          return (
            <Query
              query={GET_SESSION}
              skip={!params.session_id}
              variables={{ session: { _id: params.session_id } }}
            >
              {({ loading: sessionLoading, data: { session = {} } = {} }) => {
                if (sessionLoading)
                  return (
                    <div className="loadingContainer">
                      <Loader />
                      <span>Getting survey...</span>
                    </div>
                  );
                return (
                  <Query
                    query={GET_RESPONSE_BY_SESSION_AND_STATUS}
                    variables={{
                      response: {
                        sessionId: params.session_id,
                        status: RESPONSE_STATUS_COMPLETED,
                      },
                    }}
                    pollInterval={5000}
                  >
                    {({
                      loading: responseLoading,
                      data: { responseBySessionAndStatus } = {},
                    }) => {
                      if (responseLoading)
                        return (
                          <div className="loadingContainer">
                            <Loader />
                            <span>Getting survey...</span>
                          </div>
                        );
                      return (
                        <div className="h-100">
                          <Nav
                            survey={survey}
                            navigationType={navigationType}
                            responseBySessionAndStatus={
                              responseBySessionAndStatus
                            }
                          />
                          <Switch>
                            <Route
                              path={
                                "/survey/:survey_id/session/:session_id/results/:result_id"
                              }
                            >
                              <Results {...this.props} survey={survey} session={session}/>
                            </Route>
                            <Route
                              path={[
                                "/survey/:survey_id",
                                "/survey/:survey_id/session/:session_id",
                              ]}
                            >
                              <Page
                                {...survey.pages[page_number - 1]}
                                session={session}
                                validator={this.validator}
                                navigationType={navigationType}
                                responseBySessionAndStatus={
                                  responseBySessionAndStatus
                                }
                                onUpdating={this.onUpdating}
                                isEditEnabled={isEditEnabled}
                                survey={survey}
                              />
                            </Route>
                          </Switch>
                          <Footer
                            {...{ survey }}
                            validator={this.validator}
                            navigationType={navigationType}
                            page_number={page_number}
                            session={session}
                            isUpdating={isUpdating}
                            responseBySessionAndStatus={
                              responseBySessionAndStatus
                            }
                            isEditEnabled={isEditEnabled}
                            handleEditClick={this.handleEditClick}
                          />
                        </div>
                      );
                    }}
                  </Query>
                );
              }}
            </Query>
          );
        }}
      </Query>
    );
  }
}

Survey.defaultProps = {};

export default Survey;

const CreateSessionOnLoad = (props) => {
  const history = useHistory();
  useEffect(() => {
    const { createSession, survey, buildingId, uuid } = props;
    createSession({
      variables: {
        session: { survey: survey._id, user: 1, buildingId: buildingId },
      },
    }).then(({ data: { createSession: { _id } } }) => {
      history.push(`/survey/${uuid}/session/${_id}`);
    });
  }, []);
  return <div></div>;
};

export const END_USES = {
  AIR_DISTRIBUTION: "Air Distribution",
  CLOTHES_DRYER: "Clothes Dryer",
  CLOTHES_WASHER: "Clothes Washer",
  CLOTHES_WASHERS: "Clothes Washers",
  COMPUTING: "Computing",
  CONVEYANCE: "Conveyance",
  COOKING: "Cooking",
  COOLING: "Cooling",
  DISHWASHER: "Dishwasher",
  DISHWASHERS: "Dishwashers",
  FANS: "Fans",
  FAUCETS: "Faucets",
  FREEZERS: "Freezers",
  HEATING: "Heating",
  HOT_TUB: "Hot Tub",
  IT: "IT",
  LANDSCAPING: "Landscaping",
  LEAKS: "Leaks",
  LIGHTING: "Lighting",
  MICROWAVES: "Microwaves",
  OFFICE_EQUIPMENT: "Office Equipment",
  OTHER: "Other",
  PLUG_LOADS: "Plug Loads",
  POOLS: "Pools",
  PROCESS_LOADS: "Process Loads",
  REFRIGERATION: "Refrigeration",
  SHOWER_BATH: "Shower/Bath",
  SHW_DHW: "SHW/DHW",
  SPACE_COOLING: "Space Cooling",
  SPACE_HEATING: "Space Heating",
  TOILET: "Toilet",
  TVS: "TVs",
  VENTILATION: "Ventilation",
  WATER_DISTRIBUTION: "Water Distribution",
  WATER_HEATING: "Water Heating",
};

import React from 'react'
import { withRouter } from 'react-router';
import PageStepper from "../PageStepper/PageStepper";
import logo from '../../buildee_logo.png'
import qs from "qs";
import './Nav.css';
import Tabs from '../Tabs/Tabs';
import { SEQUENTIAL, TABS } from '../../utils/constants';

let defaultProps = {};

function getLogoSrc (theme) {
  if(!theme) return logo;
  return theme.logoSrc;
}

class Nav extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      modal: null
    }
  }

  render(){
    const { survey: { name, pages, theme, config }, match, location: { search }, navigationType, responseBySessionAndStatus } = this.props;
    const page_number = parseInt(qs.parse(search, { ignoreQueryPrefix: true }).page || 1);
    return (
      <div className="container">
        <nav className="navbar navbar-expand-md survey-nav" style={{ backgroundColor: "#fff", paddingLeft: 0 }}>
          <img className="navbar-brand surveyLogo" alt="survey logo" src={getLogoSrc(theme)}/>
          { !config?.hideTitle && <h3 className="navbar-text">{name}</h3> }
        </nav>
        {navigationType === SEQUENTIAL &&
          <PageStepper {...{ match, pages, page_number, config }} />
        }
        {navigationType === TABS &&
          <Tabs survey={this.props.survey} {...{ match, pages, page_number, responseBySessionAndStatus }} />
        }
      </div>
    )
  }
}

Nav.defaultProps = defaultProps;

export default withRouter(Nav);
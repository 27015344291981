import React, {useState, useEffect, useCallback} from "react";
import Loader from "../Loader";
import "./DataSyncer.css";

const DataSyncer = ({offlineLink, isOnline}) => {
    const [isSyncingData, setDataSyncState] = useState(false);
    const syncData = useCallback(async () => {
        setDataSyncState(true)
        await offlineLink.sync();
        setDataSyncState(false);
    }, [setDataSyncState, offlineLink])
    useEffect(() => {
        if(isOnline && offlineLink.queue.size > 0) syncData()
    }, [isOnline])
    return (
        <div className={`syncContainer ${isSyncingData ? "" : "hide"}`}>
            <Loader />
            <span>Syncing Data...</span>
        </div>
    )
}

export default DataSyncer;
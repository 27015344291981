export const generateOptimisticResponse = (answer, session, newValue, type) => {
    let answers = [...session.answers] || [];
    if(type === "updateAnswer") {
      answers = session.answers?.map(item => {
        if(item._id === answer._id) {
          return {
            ...item,
            value: newValue
          }
        }
        return item;
      })
    } else {
      answers.push({
        ...answer,
        validation: {
          isValid: true,
          message: null,
          __typename: "Validation"
        },
        isAdd: true
      })
    }
    return { [type]: {
      ...session,
      answers
    }}
  }